import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { FileIcon, defaultStyles } from "react-file-icon";
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import DropMenu from "components/Menu/DropMenu";
import cockpitActions from "redux/actions/cockpitActions";
import { getMesssageItemSelector } from "redux/selectors/cockpitSelectors";
import FileActions, {
	formatBytes,
	getFileType,
	getFileExtension,
	mimeTypeCompute
} from "helpers/actions/FileActions";

export default props => {
	return (
		<Box
			maxWidth="100%"
			maxHeight="100%"
			border="1px solid #DBDBDB"
			display="flex"
			borderRadius="4px"
			marginBottom="4px"
		>
			<Box
				width="33px"
				borderRight="solid 1px #DBDBDB"
				paddingRight="4px"
				paddingTop="4px"
				paddingLeft="4px"
			>
				<FileIcon
					extension={getFileExtension(props.name).replace(".", "")}
					{...defaultStyles[getFileExtension(props.name).replace(".", "").toLowerCase()]}
				/>
			</Box>
			<Box
				width="calc(100% - 53px)"
				maxWidth="calc(100% - 53px)"
				overflow="hidden"
				textOverflow="ellipsis"
			>
				<Tooltip title={props.name}>
					<Box px={1} overflow="hidden" fontSize="0.9em" fontWeight="500">
						{props.name}
					</Box>
				</Tooltip>
				<Box px={1} overflow="hidden" fontSize="0.7em" lineHeight="normal">
					{props.size ? formatBytes(props.size) : ""}
				</Box>
			</Box>
			{/*
			 *	Actions menu for attachments
			 */}
			<FileActions
				mID={props.mID}
				attachment={{
					// Default attachment attributes
					...props,
					// Need to recompute this attributes
					name: props.name,
					url: props.url,
					mimeType: mimeTypeCompute(props)
				}}
				customDownloadProcess={props.customDownloadProcess}
			/>
		</Box>
	);
};
