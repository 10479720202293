/* eslint-disable react/prop-types */
/*eslint-disable*/
import React from "react";
import lod_ from "lodash";
import store from "../redux/store";
import { Snackbar, Slide } from "@material-ui/core";
import i18n from "../i18n";
import axios from "axios";
var myDateTime = require("luxon");
import { isValidPhoneNumber } from "libphonenumber-js";

import parse, { attributesToProps } from "html-react-parser";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SignalWifi0BarIcon from "@material-ui/icons/SignalWifi0Bar";
import SignalWifi1BarIcon from "@material-ui/icons/SignalWifi1Bar";
import SignalWifi2BarIcon from "@material-ui/icons/SignalWifi2Bar";
import SignalWifi3BarIcon from "@material-ui/icons/SignalWifi3Bar";
import SignalWifi4BarIcon from "@material-ui/icons/SignalWifi4Bar";
import SignalCellular0BarIcon from "@material-ui/icons/SignalCellular0Bar";
import SignalCellular1BarIcon from "@material-ui/icons/SignalCellular1Bar";
import SignalCellular2BarIcon from "@material-ui/icons/SignalCellular2Bar";
import SignalCellular3BarIcon from "@material-ui/icons/SignalCellular3Bar";
import SignalCellularConnectedNoInternet4BarIcon from "@material-ui/icons/SignalCellularConnectedNoInternet4Bar";
import moment from "moment";
import "moment/locale/fr";
const { domToReact } = parse;

const style = require("style-to-object");

//channels for whom Update of sourcemarket place is  forbidden
export const UPDATE_MARKETPLACE_FORBIDDEN = ["LR", "MK", "PK", "LC"];

export const invalidFileExtensions = [
	"exe",
	"scr",
	"msi",
	"bat",
	"sh",
	"cmd",
	"com",
	"dll",
	"pif",
	"scr",
	"vb",
	"vbe",
	"vbs",
	"ws",
	"wsc",
	"wsf",
	"wsh",
	"docx"
];

export const MySnackbar = ({ transition, direction, timeout, ...rest }) => (
	<Snackbar
		anchorOrigin={{ vertical: "top", horizontal: "right" }}
		TransitionComponent={{ slide: Slide }[transition]}
		TransitionProps={{ direction, timeout }}
		{...rest}
	/>
);

export function getLabelOf(string) {
	if (lod_.isEmpty(string) || typeof string !== "string") return "";

	let labelObject;
	if (!lod_.isEmpty(store.getState().labels, "labelsMap")) {
		let labelsMap = store.getState().labels.labelsMap;
		labelObject = labelsMap.get(store.getState().selectedAssistantID + "_" + string.toLowerCase());
		// TODO : PIKA
		// How inefficient is this !! -> for each label we scroll an entire array
		// Test for Vinatis -> home page cost 1300 x 67 execution and for HSH it was 3 time more !
		// NEVER DO THIS AGAIN
		//
		// labelObject = store
		// 	.getState()
		// 	.labels[store.getState().selectedAssistantID].filter(
		// 		(object) => {
		// 			if (!lod_.isNil(object.intent)) {
		// 				return object.intent.toLowerCase() === string.toLowerCase()
		// 			}
		// 		}
		// 	)[0];
	}
	let currentLanguage;
	if (lod_.has(store.getState().user, "language")) {
		currentLanguage = store.getState().user.language.toLowerCase();
	} else currentLanguage = i18n.language; // it was 'fr'

	if (labelObject && labelObject.label && labelObject.label[currentLanguage]) {
		return labelObject.label[currentLanguage];
	}
	return string;
}

//Display  the right cellular Icon
export const getConfidenceIcon = score => {
	if (!score) {
		return <SignalWifi0BarIcon fontSize="small" style={{ color: "grey" }} />;
	} else if (score < 0.25) {
		return <SignalWifi1BarIcon fontSize="small" style={{ color: "red" }} />;
	} else if (score <= 0.5) {
		return <SignalWifi2BarIcon fontSize="small" style={{ color: "orange" }} />;
	} else if (score < 0.75) {
		return <SignalWifi3BarIcon fontSize="small" style={{ color: "blue" }} />;
	} else return <SignalWifi4BarIcon fontSize="small" style={{ color: "green" }} />;
};

//Test image size attachement with setting outgoingMailMaxSizeInMeg
export const testSizeFile = size => {
	if (size) {
		let sizeMeg = parseFloat(size / (1024 * 1024));
		let sizeLimit = store.getState()?.assistantconfig?.limit?.outgoingMailMaxSizeInMeg;

		if (sizeMeg < sizeLimit) {
			return true;
		} else {
			return `${i18n.t("EDIT_FORM.error_messages.attachmentsSizeOverLimit")}${sizeLimit} MB`;
		}
	}
	return false;
};

export const parseHtml = htmlContent => {
	// try catch sur les élements non reconnu par le DOM
	return parse(htmlContent, {
		replace: domNode => {
			if (domNode?.attribs?.style) {
				try {
					style(domNode.attribs.style);
				} catch (error) {
					delete domNode.attribs.style;
					return domToReact(domNode);
				}
			}

			if (domNode.type === "tag" && domNode.name === "a") {
				domNode.attribs.target = "_blank";
				const props = attributesToProps(domNode.attribs);
				return domToReact(<a {...props} href={props.href}></a>);
			}

			if (domNode.type === "tag" && domNode.name === "span") {
				return domNode.textContent;
			}
			if (domNode.type === "tag" && domNode.name === "style") {
				return <div></div>;
			}
		}
	});
};

//Display  the right sentiment Icon
export const getSentimentIcon = sentiment => {
	switch (sentiment) {
		case "negative":
			return <SentimentVeryDissatisfiedIcon fontSize="small" style={{ color: "red" }} />;
		case "neutral":
			return <SentimentSatisfiedIcon fontSize="small" style={{ color: "black" }} />;
		case "positive":
			return <SentimentSatisfiedAltIcon fontSize="small" style={{ color: "green" }} />;
		default:
	}
};

export function SliceWord(str, length) {
	if (!str) return "";
	if (str.length > length) {
		return str.slice(0, length) + "...";
	} else {
		return str;
	}
}

//Display  the right format Date
export function formatDate(date) {
	if (date) {
		let d1 = date.substring(0, 10);
		let d2 = date.substring(11, 16);
		return d1 + " " + d2;
	} else {
		return "";
	}
}

export function regexPhoneNumber(str) {
	const regex = /^\+(?:[0-9]){6,14}[0-9]$/;
	return !regex.test(str.trim().toLowerCase());
}

export const getPriorityIcon = priority => {
	switch (priority) {
		case 1:
			return <SignalCellular0BarIcon fontSize="small" style={{ color: "black" }} />;
		case 2:
			return <SignalCellular1BarIcon fontSize="small" style={{ color: "black" }} />;
		case 3:
			return <SignalCellular2BarIcon fontSize="small" style={{ color: "blue" }} />;
		case 4:
			return <SignalCellular3BarIcon fontSize="small" style={{ color: "orange" }} />;
		case 5:
			return (
				<SignalCellularConnectedNoInternet4BarIcon fontSize="small" style={{ color: "red" }} />
			);

		default:
	}
};

/**
 *  START
 * -----------------------------------------------------------------------------------------------------------
 * --- ChangeDeep a utiliser pour le changement d'email dans toute la strucutre de la conv
 * -----------------------------------------------------------------------------------------------------------
 *
 */

/**
 * Change a String oldVal in a conv
 * @param {conversation} conv
 * @param {String} oldVal
 * @param {String} newVal
 */
export function changeDeep(conv, oldVal, newVal) {
	if (!conv || !oldVal || !newVal) return;
	recDC(conv, oldVal, newVal, "");
	return conv;
}

/**
 * Recursively change a value
 * @param {Object} obj
 * @param {String} oldVal
 * @param {String} newVal
 * @param {*} path for dumping purpose
 */
function recDC(obj, oldVal, newVal, path) {
	for (const [key, value] of Object.entries(obj)) {
		if (value) {
			if (value == oldVal && !path.endsWith(".agent")) {
				obj[key] = newVal;
			} else if (typeof value == "object") {
				recDC(value, oldVal, newVal, path + "." + key);
			} else if (key.startsWith("uid") && !path.endsWith(".agent")) {
				let nv = obj[key].replace(oldVal, newVal);
				obj[key] = nv;
			}
		}
	}
}

/* 
si date format est dans l'assistant config  
Alors se base sur cette date pour l'ensemble des affichage des date (voir les dates provenant de datetime)
sinon afficher le format par défaut
*/

export function handleSpecialDisplayDate(ts, format = "LL", currentLanguage) {
	/*si assistantConfig has a date format, use it , else use default format LL */
	moment.locale(currentLanguage);
	if (format) {
		let date = moment(ts).format(format);
		return date;
	}
}

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * findValueInDict find a value in an object with a given path
 * @param {Object} object dict to seek
 * @param {string} path the path to find the value we seeek in the object, with format key1.[2].key3.key4
 */
function findValueInDict(object, path) {
	try {
		let steps = path.split(".");

		for (let step of steps) {
			if (step[0] == "[" && step[step.length - 1] == "]") {
				// Position in array
				let array_idx = parseInt(step.replace("[", "").replace("]", ""));

				object = object[array_idx];
			} else {
				// Key in dict
				object = object[step];
			}
		}

		return object;
	} catch (error) {
		return "";
	}
}

/**
 * PIKA : 20923.01.05 -> Nothing to do here
 */
/**
 * Delete contact on collection crm
 * @param {*} endPoint
 * @param {*} selectedAssistantID
 * @param {*} contactID
 * @param {*} user
 *
 */
export async function deleteContactCRM(endPoint, selectedAssistantID, contactID, user) {
	try {
		let res = await axios.delete(endPoint + `${selectedAssistantID}/contact/${contactID}`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${user.token}`,
				UserEmail: user.email
			}
		});
		return res;
	} catch (error) {
		// console.error("Contact not deleted check error message :", error);
		traceError(`deleteContactCRM-contactID=${contactID} failed: ${error.message}`);
		return error;
	}
}

/**
 * PIKA : 20923.01.05 -> Nothing to do here
 */
export async function deleteConversationContact(endPoint, selectedAssistantID, contact, user) {
	try {
		let res = await axios.delete(endPoint + "/conversations/contact", {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${user.token}`,
				UserEmail: user.email
			},
			data: {
				selectedAssistantID,
				contact
			}
		});
		return res;
	} catch (error) {
		// console.error("Contact not deleted check error message :", error);
		traceError(`deleteConversationContact-contact=${contact} failed: ${error.message}`);
		return error;
	}
}

/**
 * 	Used to replace variable in an answerItem
 * @param {*} answerItem
 * @param {*} context
 * @returns
 */
export function createMessageIfPossible(text, context) {
	const agent = store.getState().userStatus.auth.user;
	try {
		let res = {};

		let values = {};
		let i = 0;
		let tags = [];

		text = text.replace(/&lt;/g, "<");
		text = text.replace(/&gt;/g, ">");
		let t = text.replace(/<\?\s*([^<]+)\s*>/g, (match, tag) => {
			tags.push(tag.trim());
			i += 1;
			return `\${valuesEval["${i - 1}"]}`;
		});

		i = 0;

		for (let tagAction of tags) {
			let isApiCall = tagAction.indexOf("API.") != -1;
			let isCustomCall = tagAction.indexOf("CUSTOM.") != -1;

			if (isApiCall || isCustomCall) {
				// Don't process, let it to AB if API or CUSTOM tag
				return false;
			}

			values[i + ""] = tagAction;
			i += 1;
		}

		let valuesEval = new Array(values.length);
		for (let key in values) {
			valuesEval[key] = findValueInDict({ context, agent }, values[key]);
			if (valuesEval[key] === undefined) {
				let error = `Couldn't find key ${values[key]}, in createMessageIfPossible`;
				if (!res.error) {
					res.error = "";
				}
				res.error += error;
				valuesEval[key] = "";
			}
		}
		res.data = eval("`" + t + "`");

		return res.data;
	} catch (error) {
		return false;
	}
}

export function isLiveChatEnabled(channels) {
	if (channels != undefined) {
		for (let channel of channels) {
			if (channel.type === "LC" && channel.active) {
				return true;
			}
		}
	}
	return false;
}

export function getBackgroundColor(channels, unavailable, humanRequest) {
	if (isLiveChatEnabled(channels)) {
		if (unavailable) {
			return "black";
		} else {
			if (humanRequest) {
				return "red";
			} else {
				return "green";
			}
		}
	} else {
		return "black";
	}
}
/**
 * Decode the filename componenent of an URI
 * @param {*} uri
 * @returns
 */
export function getACleanURI(uri, typeOfProcess) {
	if (lod_.isNil(uri)) return;
	try {
		let isNewVersion =
			!uri?.includes("&attID=") && !uri?.includes("&disposition=") && !uri?.includes("&from=");

		if (isNewVersion) {
			// Example:
			// https://auth-dev-prod1.eu-de.mybluemix.net/api/v1/s3/visualizeSignedUrl?hash=59a1116230675117972fe252e0cb1a6aedc9267ac7a14c70de36d97ed05417480fad86c0de9d9c324047a52336ddd9c8dc3cba0ae71a623af65b4ec406cf708ce1160dba158629ed5bad24ea5d2af001bb0b03bff9a7cc3320bb58cfce2155bb2a1757b1de6af628388d381945cd6522d4ebb2|c3bccf0927d453f8141d07e3695f16b3
			return uri;
		}

		/**
		 * decodeURIComponent betwee &attID= and &disposition=
		 */
		const begin = "&attID=";
		const end = "&disposition=";
		const startCut = uri.indexOf(begin) + begin.length;
		const start = uri.substring(0, startCut);
		let finishCut = uri.indexOf(end);
		if (finishCut == -1) {
			const sentAttEnd = "&from=";
			finishCut = uri.indexOf(sentAttEnd);
		}
		const finish = uri.substring(finishCut);

		const key = uri.substring(startCut, finishCut);
		const keyCut = key.lastIndexOf("/") + 1;
		const path = key.substring(0, keyCut);
		const name = key.substring(keyCut);

		if (name.indexOf("#") != -1)
			return start + path + decodeURIComponent(name).replace(/#/gm, "%23") + finish;

		if (typeOfProcess === "browser") return uri; // PIKA : I know it's sub optimal but when we expose directly links to browser we need only to change the #

		return start + path + decodeURIComponent(name) + finish;
	} catch (error) {
		traceError(`getACleanURI-failed since ${error.message} uri remains ${uri}`);

		return uri;
	}
}

/**
 * All pages in cockpit and not in archived page
 */
export const PAGES_IN_COCKPIT = ["waiting", "ongoing", "resolved", "live"];
/**
 * For tab in cockpit
 */
export const LEFT_TAB_BY_CONVERSATION_TYPE = {
	waiting: 0,
	ongoing: 1,
	resolved: 2,
	live: 3
};
/**
 * For tab in archived page
 */
export const LEFT_TAB_BY_CONVERSATION_TYPE_ARCHIVED = {
	archived: 0,
	hidden: 1,
	cold: 4
};

/**
 *
 * @returns
 */
export function getConversationTypeByLeftTab(leftTab, isCockpitArchived) {
	let type;
	switch (leftTab) {
		case 0: {
			type = isCockpitArchived ? "archived" : "waiting";
			break;
		}
		case 1: {
			type = isCockpitArchived ? "hidden" : "ongoing";
			break;
		}
		case 2: {
			type = "resolved";
			break;
		}
		case 3: {
			type = "live";
			break;
		}
		case 4: {
			type = "cold";
			break;
		}
		default:
			type = "waiting";
	}
	return type;
}

/**
 *
 * @returns
 */
export function getCountByConversationType() {
	const state = store.getState().cockpit;
	const type = getConversationTypeByLeftTab(state.leftTab, state.isCockpitArchived);
	const nbWaiting = state.nbWaitingConvs;
	const nbOnGoing = state.nbOngoingConvs;
	const nbResolved = state.nbResolvedConvs;
	const nbArchived = state.nbArchivedConvs;
	const nbLiveChat = state.nbLiveConvs;
	const nbColdConvs = state.nbColdConvs;
	const nbHiddenConvs = state.nbHiddenConvs;
	switch (type) {
		case "archived":
			return nbArchived;
		case "waiting":
			return nbWaiting;
		case "ongoing":
			return nbOnGoing;
		case "resolved":
			return nbResolved;
		case "live":
			return nbLiveChat;
		case "cold":
			return nbColdConvs;
		case "hidden":
			return nbHiddenConvs;
		default:
			return nbWaiting;
	}
}

/**
 *
 * @param {*} assistantconfig
 * @returns
 */
export function getLanguagesByAssistant(assistantconfig) {
	const assistantLanguages = assistantconfig.languages;
	if (assistantLanguages) {
		const languages = assistantLanguages
			.filter(language => language.active)
			.map(language => language.language);
		return languages;
	}
	return [];
}

/**
 *
 * @param {*} assistantconfig
 * @returns
 */
export function getChannelsByAssistant(assistantconfig) {
	if (assistantconfig.channels) {
		return lod_.uniq(
			assistantconfig.channels
				.filter(c => c.filter !== false)
				.map(c => {
					return { code: c.code, type: c.type, name: c.name };
				})
		);
	}
	return [];
}

export function getTabs() {
	return {
		0: store.getState().cockpit.isCockpitArchived ? "archived" : "waiting",
		1: store.getState().cockpit.isCockpitArchived ? "hidden" : "ongoing",
		2: "resolved",
		3: "live",
		4: "cold"
	};
}

/**
 * is live if is (waiting or ongoing) and (channel "LC" or "RMS" )
 * @param {*} header
 * @returns
 */
export function isConversationLive(header) {
	if (header.state !== "waiting" && header.state !== "ongoing") {
		return false;
	}
	// if (header.type === "call") {
	// 	return true;
	// }
	if (getLiveChannels()?.includes(header.channel)) {
		return true;
	}
	return false;
}
/**
 *
 * @returns
 */
export function getLiveChannels() {
	return ["LC"];
}

/**
 *
 * @param {*} coldUrlFile
 * @param {*} assistantID
 * @param {*} userEmail
 * @param {*} userToken
 */
export const downloadColdFile = async (coldUrlFile, get) => {
	const storeState = store.getState();
	const userEmail = storeState.userStatus.auth.user.email;
	const userToken = storeState.userStatus.auth.user.token;
	const assistantID = storeState.selectedAssistantID;
	let urlColdDownload = `api/v1/cold/getAccessibleUrl`;
	let signedUrlRes = await axios.get(urlColdDownload, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${userToken}`,
			UserEmail: userEmail
		},
		params: {
			url: coldUrlFile,
			assistantID: assistantID
		},
		method: "GET"
	});

	if (signedUrlRes?.data?.authUrl) {
		let documentUrl = signedUrlRes?.data?.authUrl;

		if (documentUrl.includes("/api/v1/coldRouter/documents")) {
			const endPoint = process.env.REACT_APP_AMBACK;
			documentUrl = endPoint + documentUrl;
		}

		if (!get) {
			window.open(documentUrl, "_blank").focus();
		} else {
			return documentUrl;
		}
	} else if (get) {
		return "";
	}
};

export const assistantHasApiCold = () => {
	return lod_.some(store.getState().assistantconfig.APIs, api => api["name"] === "cold");
};

export function displayCorrectName(substate) {
	switch (substate) {
		case "waiting_client":
			return i18n.t("COC.client");

		case "waiting_action":
			return i18n.t("COC.action");

		case "resolved":
			return i18n.t("COC.resolved");

		case "alreadyResolved":
			return i18n.t("COC.alreadyResolved");

		case "watchlist":
			return i18n.t("COC.watchlist");

		case "expecting_answer":
			return i18n.t("COC.expectingAnswer");

		case "reopen":
			return i18n.t("COC.reopen");

		case "error":
			return i18n.t("COC.error");

		case "sending":
			return i18n.t("COC.sending");

		default:
			return null;
	}
}

/**
 * Check if a conv pass current selected filters
 * TODO: pass to filterReducers instead of cockpit
 * @param {*} cockpitState
 * @param {*} userEmail
 * @param {*} userRole
 * @param {*} conversation
 * @returns a boolean
 */
export const conversationPassTheFilters = (
	cockpitState,
	userEmail,
	userRole,
	conversation,
	userGroup
) => {
	// Rename the variable for better readability.
	// When user has filter to see only his conversation cockpitState.convSupervisorFilter = true, else false
	const filterOnlyMyConversations = cockpitState?.convSupervisorFilter;
	const isMineConversation = userEmail === conversation.agent?.uid;

	// Not in waitting and show mine only
	if (cockpitState.leftTab !== 0 && filterOnlyMyConversations) {
		// Not mine so don't pass the filter
		if (!isMineConversation) {
			return false;
		}
	}

	const groupCondition =
		cockpitState.convGroupFilter.length > 0
			? cockpitState.convGroupFilter.every(group =>
					conversation.header.resolverGroup?.includes(group._id)
				)
			: true;

	/**
	 * Check if the user can see conversation resolverGroup or escalationGroup
	 * Create the personal group of the user with the assistantID and the userEmail
	 * to check draft or personal conversation.
	 */
	// Personal group
	const userPersonalGroup = `${conversation?.header?.assistantID}_${userEmail}`;
	// Merge userGroup and userPersonalGroup
	const allUserGroups = [...userGroup, userPersonalGroup];
	// Condition to check if the user is in the resolverGroup or escalationGroup
	const groupConditionToCheck =
		!lod_.isNil(allUserGroups) && allUserGroups?.length > 0
			? allUserGroups.some(
					grp =>
						conversation?.header?.resolverGroup.includes(grp) ||
						conversation?.header?.escalationGroup.includes(grp)
				)
			: false;

	const intentCondition =
		cockpitState.convIntentFilter.length > 0
			? cockpitState.convIntentFilter?.includes(conversation.meta.topic)
			: true;
	const userCondition =
		cockpitState.convUserFilter.length > 0
			? cockpitState.convUserFilter?.includes(conversation.agent.uid)
			: true;

	const searchCondition =
		cockpitState.convSearchFilter.length > 0
			? conversation.meta?.topic?.includes(cockpitState.convSearchFilter) ||
				conversation.search?.topic?.includes(cockpitState.convSearchFilter) ||
				conversation.search?.cleaned?.includes(cockpitState.convSearchFilter) ||
				conversation.search?.context?.includes(cockpitState.convSearchFilter) ||
				conversation.search?.contact?.includes(cockpitState.convSearchFilter) ||
				conversation.meta?.cockpit?.contact?.includes(cockpitState.convSearchFilter) ||
				conversation.contact?.ML_id?.includes(cockpitState.convSearchFilter) ||
				conversation.header?.fID?.includes(cockpitState.convSearchFilter) ||
				conversation.meta?.cockpit?.datetime?.includes(cockpitState.convSearchFilter)
			: true;

	const dateCondition =
		(cockpitState.convStartDateFilter
			? conversation.meta?.cockpit?.ts < cockpitState.convStartDateFilter
			: true) &&
		(cockpitState.convEndDateFilter
			? conversation.meta?.cockpit?.ts > cockpitState.convEndDateFilter
			: true);

	const prioCondition = cockpitState.convPriorityFilter
		? conversation.meta?.priority === cockpitState.convPriorityFilter
		: true;
	const sentimentCondition = cockpitState.convSentimentFilter
		? conversation.meta?.sentiment === cockpitState.convSentimentFilter
		: true;
	const substateCondition = cockpitState.convSubstateFilter
		? conversation.header?.substate === cockpitState.convSubstateFilter
		: true;
	const convEscalateCondition = cockpitState.convEscalationFilter
		? conversation.header?.isEscalated === cockpitState.convEscalationFilter
		: true;
	const languageCondition = cockpitState.convLanguageFilter
		? conversation.header?.language === cockpitState.convLanguageFilter
		: true;
	const channelCondition = cockpitState.convChannelFilter
		? conversation.header?.channel === cockpitState.convChannelFilter.type
		: true;
	const connectedCondition =
		cockpitState.convConnectedFilter && cockpitState.leftTab === 3
			? conversation.meta.cockpit.connected === cockpitState.convConnectedFilter
			: true;
	const condition =
		intentCondition &&
		groupCondition &&
		userCondition &&
		searchCondition &&
		dateCondition &&
		prioCondition &&
		sentimentCondition &&
		substateCondition &&
		convEscalateCondition &&
		languageCondition &&
		channelCondition &&
		groupConditionToCheck &&
		connectedCondition;
	return condition;
};

/**
 *
 */
export const getConversationGroups = () => {
	const selectedConversation = store.getState().cockpit.selectedConversation;
	const groups = store.getState().userGroup.groups;
	let conversationGroups = selectedConversation.header.resolverGroup;
	if (!Array.isArray(conversationGroups)) {
		conversationGroups = [conversationGroups];
	}
	conversationGroups = groups.filter(group => conversationGroups.includes(group._id)); //To display only authorized groups
	return conversationGroups;
};

export function shallowEqual(object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
		return false;
	}
	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}
	return true;
}

export function getNameAgent(users, id) {
	let findUser = users.find(user => user._id === id);

	let user =
		findUser && findUser.surname && findUser.name ? findUser.surname + " " + findUser.name : id;

	return user;
}

export function validateEmail(email) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function validateNumber(str) {
	// Check if the string is empty
	if (str === "") {
		return false;
	}

	// Use the Number constructor to attempt conversion
	const number = Number(str);

	// Check if the conversion is NaN (not a number)
	// and return the opposite (valid number)
	return !isNaN(number);
}

export function validatePhone(str) {
	return isValidPhoneNumber(str);
}

export function getValidTypesForText(text) {
	const validTypes = ["string"];

	try {
		if (validateEmail(text)) {
			validTypes.push("email");
		}

		if (validateNumber(text)) {
			validTypes.push("number");
		}

		if (validatePhone(text)) {
			validTypes.push("phone");
		}
	} catch (error) {
		// console.error(error);
	}

	return validTypes;
}

/**
 * Send an error log (in data) to the backend
 * @param {*} data
 */
export async function traceError(data) {
	try {
		let storeState = store.getState();

		axios(process.env.REACT_APP_AMBACK + "/TRCERR", {
			method: "POST",
			data: {
				agent: storeState?.userStatus?.auth?.user?.email,
				app: "cockpit",
				env: process.env.REACT_APP_ENV,
				data
			}
		});
	} catch (e) {}
}

export function regexMail(str) {
	return !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		str.trim().toLowerCase()
	);
}

export function changeFavicon(iconeUrl) {
	let link = document.querySelector("link[rel~='icon']");
	if (!link) {
		link = document.createElement("link");
		link.rel = "icon";
		document.getElementsByTagName("head")[0].appendChild(link);
	}
	if (link.href !== iconeUrl) {
		link.href = iconeUrl;
	}
}

/**
 * Send the real time based on the given timestamp
 * @param {*} ts
 */
export function realNowFromTS(ts) {
	let d = myDateTime.DateTime.fromMillis(ts, { zone: "utc" });

	const dt = getDT(d);

	return {
		ts: ts,
		datetime: dt
	};
}

/**
 * Return a Javascript Date() with default timezone UTC
 */
export function realNowUtc(input) {
	if (input) {
		return new Date(input);
	}
	return new Date();
}

/**
 * Return the string in form of "2023-03-29 20:26:30.123"
 *
 * @param {LuxonDateTime} d
 * @returns a string in form of "2023-03-29 20:26:30.123"
 */
function getDT(d) {
	return `${d.year}-${("0" + d.month).slice(-2)}-${("0" + d.day).slice(-2)} ${("0" + d.hour).slice(
		-2
	)}:${("0" + d.minute).slice(-2)}:${("0" + d.second).slice(-2)}.${("00" + d.millisecond).slice(
		-3
	)}`;
}
